import React, {useState} from 'react';
import '../App.css';
import { Button } from './Button';
import './Main.css';
import axios from 'axios'
import Config from '../config.json';

function Main() {

  let [inputCount, setInputCount] = useState(0);
  let [alias, setAlias] = useState("");
  let [title, setTitle] = useState("");
  let [attendees, setAttendees] = useState("");
  let [dueDate, setDueDate] = useState("");
  let [sDate, setSDate] = useState("");
  let [loc, setLoc] = useState("");
  let [cost, setCost] = useState("");
  let [descp, setDescp] = useState("");

  const publicPath = process.env.PUBLIC_URL;

  const aliasHandler = (e) => {
    e.preventDefault();
    setAlias(e.target.value);
  };

  const titleHandler = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const attendeesHandler = (e) => {
    e.preventDefault();
    setAttendees(e.target.value);
  };

  const dueDateHandler = (e) => {
    e.preventDefault();
    setDueDate(e.target.value);
  };

  const sDateHandler = (e) => {
    e.preventDefault();
    setSDate(e.target.value);
  };

  const locHandler = (e) => {
    e.preventDefault();
    setLoc(e.target.value);
  };

  const costHandler = (e) => {
    e.preventDefault();
    setCost(e.target.value);
  };

  const onTextareaHandler = (e) => {
    e.preventDefault();
    setInputCount(e.target.value.length);
    setDescp(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if(!alias){
      alert("ID를 입력해주세요")
      return
    } 
    if(!title){
      alert("아이템명을 입력해주세요")
      return
    }
    if(!attendees){
      alert("모집인원을 입력해주세요")
      return
    }
    if(attendees < 2){
      alert("모집인원은 최소 2명 이상부터 지정 가능합니다.")
      return
    }
    if(attendees > 10){
      alert("모집인원은 8명을 초과 할 수 없습니다.")
      return
    }
    if(!dueDate){
      alert("모집마감일을 지정해주세요.")
      return
    }

    if(!sDate){
      alert("예상일시를 입력해주세요.")
      return
    }

    if(!cost){
      alert("예상비용을 입력해주세요.")
      return
    }

    if(!loc){
      alert("예상장소를 지정해주세요.")
      return
    }

    if(!descp){
      alert("상세내용을 입력해주세요")
      return
    }

    let body = {
      alias: alias,
      title: title,
      attendees: attendees,
      dueDate: dueDate,
      sDate: sDate,
      cost: cost,
      loc: loc,
      descp: descp
    };

    axios
    .post(Config.API_URL + 'item',body)
    .then((res) => {
      inputCount = 0;
      alias = "";
      title ="";
      attendees = "";
      dueDate ="";
      sDate ="";
      loc="";
      cost="";
      descp="";

      setInputCount(0);
      setAlias("");
      setTitle("");
      setAttendees("");
      setDueDate("");
      setSDate("");
      setLoc("");
      setCost("");
      setDescp("");
      alert("플래너님, 아이템 등록이 완료되었습니다.\n 롤링버디 슬랙 채널로 아이템이 공개되고 모집마감 익일 오전 추첨이 시작됩니다. ");
      return

    })
    .catch((err) => {
      console.log(err)
      alert("아이템 등록에 실패했습니다. 관리자(@mrnlee)에게 연락주시기 바랍니다.");
      return
    });
    
  };

  return (
    <div className='hero-container'>
      <h1>여유롭게 여기 모여라</h1>
      <h2>팀원분들과 함께 하고픈 아이템을 등록해주세요!</h2>
      <p>"등록하기" 버튼을 누르시면 롤링버디 슬랙채널로 아이템이 공개되고 Crew 모집이 시작됩니다.</p>
      <div className='input-areas'>
          <form id="hero-input-form">
            <p><span className='hero-input-tit'>알리아스</span><input
              className='hero-input'
              name='alias'
              placeholder='Alias'
              onChange={aliasHandler}
              value={alias}
            /></p>
            <p><span className='hero-input-tit'>아이템명</span><input
              className='hero-input-long'
              name='title'
              maxLength={30}
              placeholder=''
              onChange={titleHandler}
              value={title}
            /></p>
            <p><span className='hero-input-tit'>모집인원</span><input
              className='hero-input-long'
              name='attendees'
              type='number' min='2' max='8'
              placeholder='본인을 제외한 추첨 대상 인원을 숫자만 기입해주세요.(2명~8명)'
              onChange={attendeesHandler}
              value={attendees}
            /></p>
            <p><span className='hero-input-tit'>모집마감</span><input
              className='hero-input-long'
              name='dueDate'
              type='date'
              placeholder='날짜만 기입'
              onChange={dueDateHandler}
              value={dueDate}
            /></p>
            <p><span className='hero-input-tit'>예상일시</span><input
              className='hero-input-long'
              name='sDate'
              maxLength={30}
              placeholder='Crew 모집 후 정하셔도 됩니다. ex) 미정, 협의 후 결정, 10월4일(수)'
              onChange={sDateHandler}
              value={sDate}
            /></p>
            <p><span className='hero-input-tit'>예상비용</span><input
              className='hero-input-long'
              name='cost'
              maxLength={30}
              placeholder='ex) 3만원'
              onChange={costHandler}
              value={cost}
            /></p>
            <p><span className='hero-input-tit'>예상장소</span><input
              className='hero-input-long'
              name='loc'
              maxLength={30}
              placeholder=''
              onChange={locHandler}
              value={loc}
            /></p>
            <p className='hero-textarea-tit'>상세내용 (간략한 설명)</p>
            <p><textarea
              className='hero-textarea'
              name='descp'
              maxLength={300}
              placeholder=''
              onChange={onTextareaHandler}
              value={descp}
            /></p>
            <p style={{color:'white',textAlignLast:'right'}}>
              <span value={inputCount}>{inputCount}</span>
              <span>/300 자</span>
            </p>
            </form>
      </div>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--medium'
          onClick={submitHandler}
        >
          등록하기
        </Button>
      </div>
    </div>
  );
}

export default Main;
