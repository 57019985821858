import React from 'react';
import '../../App.css';
import Main from '../Main';

function Home() {
  return (
    <>
      <Main />
    </>
  );
}

export default Home;
