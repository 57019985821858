import React from 'react';
import './App.css';
import Home from './components/pages/Home';
import Attendees from './components/pages/Attendees';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/register' element={<Attendees />}/>
          <Route path='/' element={<Home />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
