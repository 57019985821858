import React, {useState} from 'react';
import '../App.css';
import './Main.css';
import axios from 'axios'
import Config from '../config.json';
import { useSearchParams } from 'react-router-dom';
import Attedees from './pages/Attendees';


function Register() {

  let [alias, setAlias] = useState("");
  let [id, setId] = useState("");
  let [counts, setCounts] = useState("");
  let [attendees, setAttendees] = useState("");
  let [title, setTitle] = useState("");

  const [searchParams, setSearchParams] = useSearchParams(); // 쿼리 스트링을 searchParams 형태로 가져오고
  const param_id = searchParams.get('id'); // offset 값 변수에 저장

  axios
  .get(Config.API_URL + 'attendees?id=' + param_id)
  .then((res) => {
    setTitle(res.data.title);
    setAttendees(res.data.attendees);
    setCounts(res.data.count);
    setId(param_id);
  })
  .catch((err) => console.log(err));

  const aliasHandler = (e) => {
    e.preventDefault();
    setAlias(e.target.value);
  };

  const idHandler = (e) => {
    e.preventDefault();
    setId(param_id);
  };

  const joinHandler = (e) => {
    e.preventDefault();

    if(!alias){
      alert("Alias를 입력해주세요")
      return
    }

    let body = {
      alias: alias,
      id: param_id
    };

    axios
    .post(Config.API_URL + 'member',body)
    .then((res) => {
      alias = "";
      id ="";
      setAlias("");
      setId("");
      alert("참석 신청이 완료되었습니다.\n 중복 신청인 경우, 추가로 신청되지 않으며 중복여부에 대한 안내 메시지가 표시되지 않습니다.\n 당첨결과는 모집마감 이후 롤링버디 슬랙 채널에 공개됩니다. ");
      window.location.reload();
      return

    })
    .catch((err) => {
      console.log(err)
      alert("참석 신청에 실패했습니다. 시스템 관리자(@mrnlee)에게 연락주시기 바랍니다.");
      return
    });
    
  };

  const unjoinHandler = (e) => {
    e.preventDefault();

    if(!alias){
      alert("Alias를 입력해주세요")
      return
    }

    let body = {
      alias: alias,
      id: param_id
    };

    axios
    .post(Config.API_URL + 'omember',body)
    .then((res) => {
      alias = "";
      id ="";
      setAlias("");
      setId("");
      alert("참석 취소가 완료되었습니다. ");
      window.location.reload();
      return

    })
    .catch((err) => {
      console.log(err)
      alert("참석 취소에 실패했습니다. 시스템 관리자(@mrnlee)에게 연락주시기 바랍니다.");
      return
    });
    
  };

  return (
    <div className="center">
        <p><label name="counts" className="show-btn-value-20"> {title} </label></p> 
        <p><label htmlFor="show" className="show-btn-text-30">모집 인원 : </label>
         <label name="counts" className="show-btn-value-30"> {attendees} </label></p>       
         <p><label htmlFor="show" className="show-btn-text-40">신청 인원 : </label>
         <label name="counts" className="show-btn-value-40"> {counts} </label></p>

         <input type="checkbox" id="show"/>
         <label htmlFor="show" className="show-btn">신청하기</label>
         <input type="checkbox" id="show-new"/>
         <label htmlFor="show-new" className="show-btn-new">취소하기</label>

         <div className="container">
            <label htmlFor="show" className="close-btn fas fa-times" title="close">X</label>
            <div className="text">
               참석 신청
            </div>
            <form>
               <div className="data">
                  <label>Alias</label>
                  <input
                    className='hero-input'
                    name='alias'
                    placeholder='Alias'
                    onChange={aliasHandler}
                    value={alias}
                  />
               </div>
               <div className="btn">
                  <div className="inner"></div>
                  <button
                    onClick={joinHandler}
                  >
                    신청하기
                  </button>
               </div>
            </form>
         </div>
         <div className="container-new">
            <label htmlFor="show-new" className="close-btn fas fa-times" title="close">X</label>
            <div className="text">
               취소 신청
            </div>
            <form>
               <div className="data">
                  <label>Alias</label>
                  <input
                    name='alias'
                    placeholder='Alias'
                    onChange={aliasHandler}
                    value={alias}
                  />
               </div>
               <div className="btn">
                  <div className="inner"></div>
                  <button
                    onClick={unjoinHandler}
                  >
                    취소하기
                  </button>
               </div>
            </form>
         </div>
      </div>
  );
}

export default Register;
