import React from 'react';
import '../../App.css';
import Attedees from '../Register';

function Register() {
  return (
    <>
      <Register />
    </>
  );
}

export default Attedees
;
